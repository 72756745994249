<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div
              class="wizard wizard-2"
              id="kt_wizard_v2"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
            >
              <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                <div class="row">
                  <div class="col-md-6 col-lg-6 col-sm-12">
                    <h4>Update quiz</h4>
                    <router-link :to="{ name: 'dashboard' }"> Dashboard </router-link>
                    \ Quiz
                  </div>
                  <div class="col-6 float-right pull-right">
                    <v-btn
                      class="float-right pull-right btn btn-primary text-white ml-2"
                      @click="createOrUpdate"
                      >Save
                    </v-btn>
                    <v-btn
                      class="float-right pull-right btn cancel-btn"
                      text
                      @click="redirectToQuiz"
                    >
                      Cancel
                    </v-btn>
                  </div>
                </div>
                <v-row>
                  <v-col cols="12">
                    <v-tabs v-model="tab">
                      <v-tab> Questions </v-tab>
                      <v-tab> Settings </v-tab>
                    </v-tabs>
                  </v-col>
                  <v-col cols="12">
                    <v-tabs-items v-model="tab">
                      <v-tab-item>
                        <v-row class="mt-2">
                          <v-col cols="4">
                            <v-select
                              outlined
                              dense
                              label="Category"
                              :items="categories"
                              item-text="title"
                              item-value="id"
                              v-model="category_id"
                              @change="getQuesitons"
                            >
                            </v-select>
                          </v-col>

                          <v-col cols="5">
                            <v-autocomplete
                              multiple
                              v-model="selectedQuestion"
                              :items="questions"
                              return-object
                              dense
                              outlined
                              chips
                              deletable-chips
                              item-value="id"
                              item-text="question"
                              label=" Add question from question bank"
                              placeholder=" Question from question bank"
                              hint=" Question from question bank"
                              persistent-hint
                              append-icon="mdi-file-document-box-plus"
                              @click:append="addToQuizList"
                            >
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="3">
                            <v-btn
                              class="btn btn-primary text-white btn-block"
                              :disabled="!category_id"
                              @click.prevent="addAllCategoryQuestions"
                            >
                              Add all from selected category
                            </v-btn>
                          </v-col>
                          <v-col cols="12">
                            <div class="table-responsive">
                              <v-alert
                                text
                                outlined
                                v-if="
                                  quiz_questions.length > this.quiz.total_number_question
                                "
                                color="deep-orange"
                                icon="mdi-fire"
                              >
                                Only {{ this.quiz.total_number_question }} question
                                allowed.
                              </v-alert>
                              <table class="table">
                                <thead>
                                  <tr class="px-3">
                                    <th class="px-3 wrap-column">#</th>
                                    <th class="px-3 wrap-column">Question No</th>
                                    <th class="px-3 wrap-column">Questions</th>
                                    <th class="px-3 wrap-column">Category</th>
                                    <th class="px-3 text-center">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-show="quiz_questions.length > 0"
                                    v-for="(item, index) in quiz_questions"
                                    :key="item.question"
                                  >
                                    <td class="wrap-column">{{ index + 1 }}</td>
                                    <td class="wrap-column">{{ item.question_no }}</td>
                                    <td class="wrap-column">{{ item.question }}</td>
                                    <td class="wrap-column">
                                      {{ item.category ? item.category.title : null }}
                                    </td>

                                    <td class="pr-0 px-1 text-center">
                                      <template>
                                        <b-dropdown
                                          size="sm"
                                          variant="link"
                                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                          no-caret
                                          right
                                          no-flip
                                        >
                                          <template v-slot:button-content>
                                            <i class="ki ki-bold-more-hor"></i>
                                          </template>
                                          <!--begin::Navigation-->
                                          <div class="navi navi-hover min-w-md-250px">
                                            <b-dropdown-text tag="div" class="navi-item">
                                              <a
                                                href="#"
                                                @click.prevent="
                                                  removeQuestion(index, item.id)
                                                "
                                                class="navi-link"
                                              >
                                                <span class="navi-icon">
                                                  <i class="fas fa-trash"></i>
                                                </span>
                                                <span class="navi-text"> Delete</span>
                                              </a>
                                            </b-dropdown-text>
                                          </div>
                                        </b-dropdown>
                                      </template>
                                    </td>
                                  </tr>
                                  <tr v-show="quiz_questions.length == 0">
                                    <td class="text-center" colspan="5">
                                      No data available.
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </v-col>
                        </v-row>
                      </v-tab-item>

                      <v-tab-item>
                        <v-card flat>
                          <v-card-text>
                            <v-row class="mt-2">
                              <v-col cols="4">
                                <v-text-field
                                  outlined
                                  dense
                                  :error="$v.quiz.title.$error"
                                  label="Title"
                                  v-model="quiz.title"
                                >
                                </v-text-field>
                                <span class="text-danger" v-if="$v.quiz.title.$error"
                                  >This information is required</span
                                >
                              </v-col>
                              <!--                          <v-col cols="3">-->
                              <!--                            <v-select-->
                              <!--                                label="Type"-->
                              <!--                                v-model="quiz.type"-->
                              <!--                                :items="types"-->
                              <!--                                item-text="title"-->
                              <!--                                item-value="value"-->
                              <!--                                outlined-->
                              <!--                                dense-->
                              <!--                            >-->
                              <!--                            </v-select>-->
                              <!--                          </v-col>-->
                              <!--                                    <v-col cols="12">-->
                              <!--                                        <v-select-->
                              <!--                                                label="Academic Year"-->
                              <!--                                                v-model="question_bank.academic_year_id"-->
                              <!--                                                :items="academic_years"-->
                              <!--                                                item-text="title"-->
                              <!--                                                item-value="id"-->
                              <!--                                                outlined-->
                              <!--                                                dense-->
                              <!--                                        >-->
                              <!--                                        </v-select>-->
                              <!--                                    </v-col>-->
                              <v-col cols="4">
                                <v-select
                                  label="Program"
                                  v-model="quiz.program_id"
                                  :items="programs"
                                  item-text="title"
                                  item-value="id"
                                  outlined
                                  :error="$v.quiz.program_id.$error"
                                  dense
                                >
                                </v-select>
                                <span class="text-danger" v-if="$v.quiz.program_id.$error"
                                  >This information is required</span
                                >
                              </v-col>
                              <v-col cols="4">
                                <v-text-field
                                  @change="changeQuestionTime"
                                  label="Total number question"
                                  type="number"
                                  v-model="quiz.total_number_question"
                                  outlined
                                  :error="$v.quiz.total_number_question.$error"
                                  dense
                                >
                                </v-text-field>
                                <span
                                  class="text-danger"
                                  v-if="$v.quiz.total_number_question.$error"
                                  >This information is required</span
                                >
                              </v-col>
                              <!--                                    <v-col cols="12">-->
                              <!--                                        <v-select-->
                              <!--                                                label="Courses"-->
                              <!--                                                v-model="question_bank.course_id"-->
                              <!--                                                :items="courses"-->
                              <!--                                                item-text="title"-->
                              <!--                                                item-value="id"-->
                              <!--                                                outlined-->
                              <!--                                                dense-->
                              <!--                                        >-->
                              <!--                                        </v-select>-->
                              <!--                                    </v-col>-->
                              <div class="col-12">
                                <h4>Schedule date and time setting</h4>
                              </div>

                              <v-col cols="4">
                                <v-menu
                                  ref="menu"
                                  v-model="menu"
                                  :close-on-content-click="false"
                                  :return-value.sync="date"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="quiz.schedule_at"
                                      label="Scheduled at"
                                      append-icon="mdi-calendar"
                                      readonly
                                      outlined
                                      dense
                                      v-bind="attrs"
                                      :error="$v.quiz.schedule_at.$error"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="quiz.schedule_at"
                                    no-title
                                    scrollable
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="menu = false">
                                      Cancel
                                    </v-btn>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="$refs.menu.save(date)"
                                    >
                                      OK
                                    </v-btn>
                                  </v-date-picker>
                                </v-menu>
                                <span
                                  class="text-danger"
                                  v-if="$v.quiz.schedule_at.$error"
                                  >This information is required</span
                                >
                              </v-col>

                              <v-col cols="4  ">
                                <v-text-field
                                  outlined
                                  v-model="quiz.start_time"
                                  dense
                                  type="time"
                                  label="Start time"
                                >
                                </v-text-field>
                              </v-col>

                              <v-col cols="4">
                                <v-text-field
                                  outlined
                                  @focusout="changeQuestionTime"
                                  v-model="quiz.duration"
                                  dense
                                  type="number"
                                  :error="$v.quiz.duration.$error"
                                  append-icon="mdi-clock"
                                  label="Duration"
                                >
                                </v-text-field>
                                <span class="text-danger" v-if="$v.quiz.duration.$error"
                                  >This information is required</span
                                >
                              </v-col>
                              <v-col cols="4">
                                <v-select
                                  outlined
                                  :items="duration_types"
                                  item-text="text"
                                  item-value="value"
                                  v-model="quiz.duration_type"
                                  :error="$v.quiz.duration_type.$error"
                                  dense
                                  label="Duration unit"
                                >
                                </v-select>
                                <span
                                  class="text-danger"
                                  v-if="$v.quiz.duration_type.$error"
                                  >This information is required</span
                                >
                              </v-col>
                              <v-col cols="4" v-if="quiz.display_per_page <= 1">
                                <v-text-field
                                  outlined
                                  @focusout="changeQuestionTime"
                                  v-model="quiz.time_length"
                                  dense
                                  min="1"
                                  type="number"
                                  suffix="minutes"
                                  label="Time per question"
                                >
                                </v-text-field>
                              </v-col>
                              <div class="col-12">
                                <h4>Questions settings</h4>
                              </div>
                              <v-col cols="4">
                                <v-text-field
                                  outlined
                                  v-model="quiz.passcode"
                                  dense
                                  label="Pass code"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="4">
                                <v-select
                                  label="Question order"
                                  :items="questions_order"
                                  item-text="title"
                                  item-value="value"
                                  outlined
                                  v-model="quiz.question_order"
                                  dense
                                >
                                </v-select>
                              </v-col>
                              <v-col cols="4">
                                <v-select
                                  label="Answer order"
                                  :items="answers_order"
                                  item-text="title"
                                  item-value="value"
                                  outlined
                                  v-model="quiz.answer_order"
                                  dense
                                >
                                </v-select>
                              </v-col>
                              <v-col cols="4">
                                <v-text-field
                                  outlined
                                  @change="changeDisplayPerPage()"
                                  v-model="quiz.display_per_page"
                                  dense
                                  @blur="controlDisplayPageDigit"
                                  type="number"
                                  placeholder="10 questions"
                                  label="Display per page"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="4">
                                <v-text-field
                                  outlined
                                  v-model="quiz.no_of_attempts_allowed"
                                  dense
                                  min="1"
                                  type="number"
                                  placeholder="No of attempt allowed"
                                  label="Attempt allowed"
                                >
                                </v-text-field>
                              </v-col>

                              <div class="col-12">
                                <h4>Result settings</h4>
                              </div>
                              <v-col cols="4">
                                <v-text-field
                                  outlined
                                  v-model="quiz.total_marks"
                                  dense
                                  :error="$v.quiz.total_marks.$error"
                                  type="number"
                                  placeholder="Total mark"
                                  label="Total mark"
                                >
                                </v-text-field>
                                <span
                                  class="text-danger"
                                  v-if="$v.quiz.total_marks.$error"
                                  >This information is required</span
                                >
                              </v-col>
                              <v-col cols="4">
                                <v-text-field
                                  outlined
                                  v-model="quiz.pass_mark"
                                  dense
                                  :error="$v.quiz.pass_mark.$error"
                                  type="number"
                                  placeholder="Pass mark"
                                  label="Pass mark"
                                >
                                </v-text-field>
                                <span class="text-danger" v-if="$v.quiz.pass_mark.$error"
                                  >This information is required</span
                                >
                              </v-col>
                              <v-col cols="4">
                                <v-text-field
                                  outlined
                                  v-model="quiz.mark_per_question"
                                  dense
                                  type="number"
                                  placeholder="Mark per question"
                                  :error="$v.quiz.mark_per_question.$error"
                                  label="Mark per question"
                                >
                                </v-text-field>
                                <span
                                  class="text-danger"
                                  v-if="$v.quiz.mark_per_question.$error"
                                  >This information is required</span
                                >
                              </v-col>
                              <div class="col-12"></div>
                              <v-col cols="4">
                                Negative marking
                                <v-switch
                                  outlined
                                  v-model="quiz.negative_marking"
                                  dense
                                  :label="quiz.negative_marking ? 'Yes' : 'No'"
                                >
                                </v-switch>
                              </v-col>
                              <v-col cols="4">
                                Enable feedBack
                                <v-switch
                                  outlined
                                  v-model="quiz.feedback_enable"
                                  dense
                                  :label="quiz.feedback_enable ? 'Yes' : 'No'"
                                >
                                </v-switch>
                              </v-col>
                              <v-col cols="4">
                                Display result
                                <v-switch
                                  outlined
                                  v-model="quiz.display_result"
                                  dense
                                  :label="quiz.display_result ? 'Yes' : 'No'"
                                >
                                </v-switch>
                              </v-col>

                              <v-col cols="4">
                                Pausable
                                <v-switch
                                  outlined
                                  v-model="quiz.is_pausable"
                                  dense
                                  :label="quiz.is_pausable ? 'Yes' : 'No'"
                                >
                                </v-switch>
                              </v-col>
                              <v-col cols="4">
                                Password protected
                                <v-switch
                                  outlined
                                  v-model="quiz.is_protected"
                                  dense
                                  :label="quiz.is_protected ? 'Yes' : 'No'"
                                >
                                </v-switch>
                              </v-col>
                              <v-col cols="4">
                                Display all question on sidebar
                                <v-switch
                                  outlined
                                  v-model="quiz.show_questions"
                                  dense
                                  :label="quiz.show_questions ? 'Yes' : 'No'"
                                >
                                </v-switch>
                              </v-col>
                              <v-col cols="4">
                                Status
                                <v-switch
                                  outlined
                                  v-model="quiz.is_active"
                                  dense
                                  :label="quiz.is_active ? 'Active' : 'Inactive'"
                                >
                                </v-switch>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-col>
                </v-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import { QuizMixin } from "@/mixins/QuizMixin";
import QuizService from "@/core/services/quiz/QuizService";
import { required, requiredIf } from "vuelidate/lib/validators";

const quizService = new QuizService();

export default {
  name: "QuizCreateUpdte",
  mixins: [QuizMixin],
  validations: {
    quiz: {
      title: { required },
      program_id: { required },
      total_number_question: { required },
      total_marks: { required },
      pass_mark: { required },
      mark_per_question: { required },
      schedule_at: { required },
      duration: {
        required: requiredIf(function (nestedModel) {
          return !this.quiz.time_length;
        }),
      },
      duration_type: {
        required: requiredIf(function (nestedModel) {
          return !this.quiz.time_length;
        }),
      },
    },
  },
  data() {
    return {
      isBusy: false,
      edit: false,
      menu: false,
      tab: null,
      question_ids: null,
      category_id: null,
      selectedQuestion: null,
      date: new Date().toISOString().substr(0, 10),
      quiz_questions: [],
      questions_order: [
        { title: "Random", value: "random" },
        { title: "Serial", value: "serial" },
      ],
      answers_order: [
        { title: "Random", value: "random" },
        { title: "Serial", value: "serial" },
      ],
      duration_types: [
        { text: "Minute", value: "minute" },
        { text: "Hour", value: "hour" },
      ],
    };
  },
  mounted() {
    this.__getQuiz();
    this.questionBankCategories();
  },
  computed: {
    quiz_id() {
      return this.$route.params.quiz_id;
    },
  },
  methods: {
    addToQuizList() {
      if (this.quiz.total_number_question) {
        let exists = this.quiz_questions.some((question) =>
          this.selectedQuestion.includes(question)
        );
        if (!exists) {
          this.selectedQuestion.forEach((ele) => {
            this.quiz_questions.push(ele);
          });

          let question_ids = this.quiz_questions.map((question) => {
            return question.id;
          });
          this.quiz.question_ids = question_ids;
          this.selectedQuestion = [];
          this.$snotify.success(
            "Question has been  added to list. Please save the list to trigger changes"
          );
        } else {
          this.$snotify.error("Question has been already added to list");
        }
        this.selectedQuestion = null;
      } else {
        this.$v.quiz.$touch();
        this.tab = 1;
      }
    },
    addAllCategoryQuestions() {
      if (!this.quiz.total_number_question) {
        this.$v.quiz.$touch();
        this.tab = 1;
      } else {
        this.questionsByCategory();
      }
    },
    addAllCategoryQuestionToList() {
      let index = 1;
      this.category_questions.forEach((catQuestion) => {
        var exists = this.quiz_questions.some((question) => {
          return question.id === catQuestion.id;
        });
        if (!exists) {
          if (this.quiz_questions.push(catQuestion)) {
            index += 1;
          }
          let question_ids = this.quiz_questions.map((question) => {
            return question.id;
          });
          this.quiz.question_ids = question_ids;
        }
      });
      this.$snotify.success(
        `${index} new entries has been added to list. Please save to trigger changes`
      );
    },

    __getQuiz() {
      quizService.show(this.quiz_id).then((response) => {
        this.quiz = response.data.quiz;
        this.edit = true;
        this.getQuesitons();
        this.getQuizQuestions(this.quiz_id);
      });
    },
    getQuesitons() {
      this.getQuestionsByProgram(this.quiz.program_id, this.category_id);
    },
    createOrUpdate() {
      if (this.quiz_questions.length > this.quiz.total_number_question) {
        this.$snotify.error("Question limit reached");
      } else {
        this.$v.quiz.$touch();
        if (this.$v.quiz.$error) {
          this.tab = 1;
          setTimeout(() => {
            this.$v.quiz.$reset();
          }, 3000);
        } else {
          let fd = this.quiz;
          if (this.edit) {
            this.__updateQuiz(fd);
          } else {
            this.__createQuiz(fd);
          }
        }
      }
    },
    removeQuestion(index, id = null) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            if (id) {
              this.deleteSelectedQuizQuestion(this.quiz.id, id);
            }
            this.quiz_questions.splice(index, 1);
          }
        },
      });
    },
    changeQuestionTime() {
      if (this.quiz.time_length && this.quiz.total_number_question) {
        this.quiz.duration = this.quiz.time_length * this.quiz.total_number_question;
      }
      if (this.quiz.time_length <= 0) {
        this.quiz.time_length = null;
      }
    },
    changeDisplayPerPage() {
      if (this.quiz.display_per_page > 1) {
        this.quiz.time_length = null;
      }
      if (this.quiz.display_per_page > 10) {
        this.quiz.display_per_page = 10;
      }
    },
    redirectToQuiz() {
      this.$router.push({ name: "quiz" });
    },
    controlDisplayPageDigit() {
      if (this.quiz.display_per_page > 10) {
        this.quiz.display_per_page = 10;
      }
      if (!this.quiz.display_per_page) {
        this.quiz.display_per_page = 1;
      }
    },
  },
};
</script>
